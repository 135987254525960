import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Container, Grid, GridItem, Heading, Text } from '@charlietango/ui';
import { rem } from 'polished';

import { CollectionState } from '../../api/api-types';
import { useParty } from '../../api/hooks/useParty';
import { useUser } from '../../api/hooks/useUser';
import DocumentTitle from '../../application/DocumentTitle';
import Anchor from '../../components/Anchor/Anchor';
import Arrow from '../../components/Arrow/Arrow';
import ElectionTypeNavigation from '../../components/ElectionTypeNavigation/ElectionTypeNavigation';
import HowToSection from '../../components/HowToSection/HowToSection';
import InitiateDeclaration from '../../components/InitiateDeclaration/InitiateDeclaration';
import InitiateDeclarationViewModel from '../../components/InitiateDeclaration/types/InitiateDeclarationViewModel';
import MyDeclaration from '../../components/MyDeclaration/MyDeclaration';
import PartyDetails from '../../components/PartyDetails/PartyDetails';
import Placeholder from '../../components/Placeholder/Placeholder';
import { useElectionType, usePartyId } from '../../hooks/useSearchParams';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { motion } from '../../utils/motion';
import AccordionViewModel from '../../view-models/AccordionViewModel';
import LinkViewModel from '../../view-models/LinkViewModel';
import { NotFound } from '../NotFound/NotFound';
import NotFoundViewModel from '../NotFound/types/NotFoundViewModel';
import { PlausibleGoals, trackEvent } from '../../utils/tracking';

export type PartyPageProps = {
  /** Intro labels based on what elections a party is applying for */
  introLabels?: {
    dk: string;
    eu: string;
    all: string;
  };
  initiateDeclaration?: InitiateDeclarationViewModel;
  howTo?: AccordionViewModel;
  attentionAnalogue?: LinkViewModel;
  notFound?: NotFoundViewModel;
};

export function PartyPage({
  attentionAnalogue,
  introLabels,
  initiateDeclaration,
  notFound,
  howTo,
}: PartyPageProps) {
  const { user, userReady } = useUser();
  const { partyId } = usePartyId();
  const { electionType, electionTypeValue } = useElectionType();
  const { data: party, error } = useParty(partyId);

  useEffect(() => {
    if (party) {
      trackEvent(PlausibleGoals.Party, {
        props: party.name ? { parti: party.name } : {},
      });
    }
  }, [party]);

  if (error || (userReady && !partyId)) {
    // If we got an error when fetching the party, or if there isn't a valid partyId
    return <NotFound {...notFound} />;
  }
  if (!party) return <Placeholder css={{ height: 600 }} />;

  // Figure out the intro text to show. It's based on the elections the parties are running for.
  let introText;
  const dkRunning = party.dk && party.dk.state !== CollectionState.noCollection;
  const euRunning = party.eu && party.eu.state !== CollectionState.noCollection;

  if (dkRunning && euRunning) introText = introLabels?.all;
  else if (dkRunning) introText = introLabels?.dk;
  else if (euRunning) introText = introLabels?.eu;

  // Get the collection state for the party
  const collectionState: CollectionState =
    party && party[electionTypeValue]
      ? (party[electionTypeValue].state as CollectionState)
      : CollectionState.noCollection;

  return (
    <Container
      as="section"
      sx={{
        pt: ['2rem', '4rem', '4.5rem'],
        pb: ['3.75rem', '4rem', '7rem'],
      }}
    >
      {process.env.NODE_ENV !== 'test' && <DocumentTitle value={party.name} />}
      <header>
        <Heading as="h1" variant={TextVariant.H1}>
          {party.name}
        </Heading>
        {introText && (
          <div sx={{ mt: [3, 0, 2], maxWidth: '70ch' }}>
            <Text variant={TextVariant.Paragraph}>{introText}</Text>
          </div>
        )}
      </header>
      <ElectionTypeNavigation
        sx={{
          mt: rem(56),
        }}
      />
      <div sx={{ pt: ['2rem', '4rem', '4.5rem'] }}>
        <Grid>
          <GridItem size={[1, 1 / 2]}>
            {party && <PartyDetails sx={{ mb: 8 }} party={party} />}

            <InitiateDeclaration
              user={user}
              electionType={electionType}
              partyId={party.id}
              partyName={party.name}
              collectionState={collectionState}
              model={initiateDeclaration}
            />
          </GridItem>
          <GridItem size={[0, 0, 1 / 12]} />
          <GridItem size={[1, 1 / 2, 5 / 12]}>
            {userReady ? (
              <>
                {user ? (
                  <MyDeclaration user={user} />
                ) : (
                  <>
                    {howTo && <HowToSection {...howTo} />}
                    {attentionAnalogue && (
                      <motion.div whileHover="hover">
                        <Anchor
                          href={attentionAnalogue.href}
                          target={attentionAnalogue.target}
                          sx={{
                            backgroundColor: Colors.PureWhite,
                            display: 'flex',
                            minHeight: '3.5rem',
                            mt: 8,
                            px: 5,
                            py: 4,
                            alignItems: 'center',
                            textDecoration: 'none',
                            boxShadow: '0 1px 0 0 #eee, inset 0 1px 0 0 #eee',
                          }}
                        >
                          <Text
                            as="span"
                            variant={TextVariant.H4}
                            sx={{ flex: 1 }}
                          >
                            {attentionAnalogue.label}
                          </Text>
                          <Arrow />
                        </Anchor>
                      </motion.div>
                    )}
                  </>
                )}
              </>
            ) : (
              <Placeholder />
            )}
          </GridItem>
        </Grid>
      </div>
    </Container>
  );
}

PartyPage.displayName = 'PartyPage';

export default !process.env.HOT ? PartyPage : hot(PartyPage);
