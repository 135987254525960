import React from 'react';
import { ElectionType, electionTypeMap } from '../../api/api-types';
import { useRouter } from '../../application/Router';
import { useDictionary } from '../../hooks/useDictionary';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import { generateRedirectUrl } from '../../utils/url-helpers';
import { DeclarationActionTypes } from '../../view-models/DeclarationActionTypes';
import Arrow from '../Arrow/Arrow';
import Button from '../Button/Button';

type Props = {
  partyId: string;
  electionType: ElectionType;
  disabled?: boolean;
  action: string;
  isOpen?: boolean;
  onDismiss?: () => void;
};

type FormProps = {
  children: React.ReactNode;
  action: string;
  partyId: string;
  electionType: ElectionType;
  service: 'nemid' | 'mitid';
};

const DeclarationForm = ({
  children,
  action,
  electionType,
  partyId,
  service,
}: FormProps) => {
  const { pathname, searchParams } = useRouter();

  // Create the return SearchParams, including any existing params
  const params = new URLSearchParams(searchParams);
  params.set('election', electionTypeMap[electionType]);
  params.set('party', partyId);
  params.set('action', action);

  const actionUrl = generateRedirectUrl(
    `/api/v1/signing/${service}/${action}`,
    pathname + '?' + params.toString(),
  );

  return (
    <form
      action={actionUrl}
      onSubmit={
        process.env.STORYBOOK
          ? (event) => {
              // We can't use the form action in storybook, so prevent it
              event.preventDefault();
              // eslint-disable-next-line no-console
              console.log('Submit', actionUrl);
            }
          : undefined
      }
      sx={{ textAlign: 'center' }}
      method="POST"
    >
      <input type="hidden" name="electionType" value={electionType} />
      <input type="hidden" name="partyId" value={partyId} />
      {children}
    </form>
  );
};

export const ConfirmDeclaration = ({
  disabled,
  partyId,
  electionType,
}: Props) => {
  const { t } = useDictionary();

  return (
    <DeclarationForm
      action={DeclarationActionTypes.confirm}
      partyId={partyId}
      service="mitid"
      electionType={electionType}
    >
      <Button
        icon={<Arrow />}
        variant={ButtonVariant.Primary}
        disabled={disabled}
        sx={{ mt: 7 }}
        type="submit"
      >
        {t('myDeclaration.status_13_50')}
      </Button>
    </DeclarationForm>
  );
};

export const WithdrawDeclaration = ({ partyId, electionType }: Props) => {
  const { t } = useDictionary();

  return (
    <DeclarationForm
      action={DeclarationActionTypes.withdraw}
      partyId={partyId}
      electionType={electionType}
      service="mitid"
    >
      <Button
        sx={{
          textDecoration: 'underline',
          color: Colors.Secondary,
          my: 6,
          width: 'auto',
          p: 0,
        }}
        type="submit"
      >
        {t('myDeclaration.status_13_51')}
      </Button>
    </DeclarationForm>
  );
};
