export const dotFormatDate = (date) => {
  const d = new Date(date);
  // Ensure the date is actually valid
  if (isNaN(d.getTime())) return '';

  const day = `0${d.getDate()}`.slice(-2);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

export const thousandsSeparators = (value: number) => {
  let num_parts = value.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return num_parts.join(',');
};
