import React from 'react';
import { Text } from '@charlietango/ui';

import { CollectionState, collectionStateMap } from '../../api/api-types';
import { useDictionary } from '../../hooks/useDictionary';
import { useElectionType } from '../../hooks/useSearchParams';
import StatusIcon from '../../icons/StatusIcon';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { itemStyle } from './PartyDetailsItem';

type Props = {
  status?: CollectionState;
};

function Status({ status }: Props) {
  const { electionTypeValue } = useElectionType();
  const runningState = status === CollectionState.running;
  const { t } = useDictionary();
  if (status === undefined || electionTypeValue === undefined) return null;

  return (
    <div
      sx={{
        ...itemStyle,
        marginBottom: 0,
        backgroundColor: !runningState ? Colors.Warning : 'transparent',
        px: !runningState ? 5 : 0,
        py: !runningState ? 5 : 4,
      }}
    >
      {runningState ? (
        <>
          <Text
            as="dt"
            variant={TextVariant.Paragraph}
            sx={{ flex: '0 0 auto', mr: 4 }}
          >
            {t('party.state')}
          </Text>
          <Text as="dd">{t(`partyStatus.${collectionStateMap[status]}`)}</Text>
        </>
      ) : (
        <>
          <dt sx={{ flex: '0 0 auto', mr: 4 }}>
            <StatusIcon sx={{ mr: 3 }} />
            <Text as="span" variant={TextVariant.Paragraph}>
              {t('party.state')}
            </Text>
          </dt>
          <Text
            as="dd"
            sx={{ textAlign: 'right' }}
            variant={TextVariant.Paragraph}
          >
            {t(`partyStatus.${collectionStateMap[status]}`, {
              electionType: t(`${electionTypeValue}Election`),
            })}
          </Text>
        </>
      )}
    </div>
  );
}

export default Status;
