import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Container, Heading } from '@charlietango/ui';
import { rem } from 'polished';

import Arrow from '../../components/Arrow/Arrow';
import Button from '../../components/Button/Button';
import RichText from '../../components/RichText/RichText';
import { ButtonVariant } from '../../styles/buttons';
import { TextVariant } from '../../styles/typography';
import NotFoundViewModel from './types/NotFoundViewModel';

export function NotFound({ title, text, link }: NotFoundViewModel) {
  return (
    <Container
      sx={{
        maxWidth: '40rem',
        textAlign: 'center',
        mt: [rem(128), rem(248)],
        mb: [rem(128), rem(248)],
      }}
    >
      {title && (
        <Heading as="h1" variant={TextVariant.H2}>
          {title}
        </Heading>
      )}
      {text && (
        <RichText sx={{ mb: 8 }} variant={TextVariant.Paragraph}>
          {text}
        </RichText>
      )}
      {link && (
        <Button
          {...link}
          prefetch
          variant={ButtonVariant.Flat}
          sx={{
            pr: 8,
            width: 'auto',
          }}
          icon={<Arrow sx={{ height: '1em', ml: 4, mt: '-2px' }} />}
        >
          {link.label}
        </Button>
      )}
    </Container>
  );
}

NotFound.displayName = 'NotFound';

export default !process.env.HOT ? NotFound : hot(NotFound);
