import React from 'react';
import { Heading, Text } from '@charlietango/ui';

import {
  SchemaPartyDetails,
  SchemaPartyDetailsResponse,
} from '../../api/api-schema';
import { CollectionState } from '../../api/api-types';
import { useDictionary } from '../../hooks/useDictionary';
import { useElectionType } from '../../hooks/useSearchParams';
import OpenNewWindowIcon from '../../icons/OpenNewWindowIcon';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { dotFormatDate, thousandsSeparators } from '../../utils/format-utils';
import Anchor from '../Anchor/Anchor';
import PartyDetailsItem, { itemStyle } from './PartyDetailsItem';
import Status from './Status';

export type PartyDetailsProps = {
  party?: SchemaPartyDetailsResponse;
  className?: string;
  children?: React.ReactNode;
};

function formatUrl(url: string) {
  if (!/^https?:\/\//.test(url)) {
    // Ensure we add the protocol to the URL
    return `https://${url}`;
  }
  return url;
}

function PartyDetails({ party, className, children }: PartyDetailsProps) {
  const { electionTypeValue } = useElectionType();
  const { t } = useDictionary();

  const currentParty: SchemaPartyDetails =
    party && electionTypeValue ? party[electionTypeValue] : undefined;

  if (
    !party ||
    !currentParty ||
    currentParty.state === CollectionState.noCollection
  ) {
    return <Status status={CollectionState.noCollection} />;
  }

  return (
    <div className={className}>
      {party.name && (
        <Heading as="h2" variant={TextVariant.H3} sx={{ marginBottom: 7 }}>
          {party.name}
        </Heading>
      )}
      <dl css={{ margin: 0 }}>
        {party.representative?.name ? (
          /*
            - IF there is only representative, it will be visible in party details as "Repræsentant".
            - IF there is only owner, it will be visible in party details as "Indehaver".
            - IF there are both representative and owner, only "Repræsentant" is visible in party details.
          */
          <PartyDetailsItem
            label={t('party.representative')}
            value={party.representative.name}
          />
        ) : party.owner?.name ? (
          <PartyDetailsItem label={t('party.owner')} value={party.owner.name} />
        ) : null}
        {currentParty.voteCount !== undefined ? (
          <PartyDetailsItem
            label={t('party.voteCount')}
            ariaValue={currentParty.voteCount.toString()}
            value={thousandsSeparators(currentParty.voteCount)}
          />
        ) : null}

        <Status status={currentParty.state} />
        {currentParty.expiryDate ? (
          <PartyDetailsItem
            label={t('party.expiryDate')}
            value={
              <time dateTime={currentParty.expiryDate}>
                {dotFormatDate(currentParty.expiryDate)}
              </time>
            }
          />
        ) : null}
        {party.contact?.address ? (
          <PartyDetailsItem
            label={t('party.address')}
            value={
              <address css={{ fontStyle: 'normal' }}>
                {party.contact.address}
              </address>
            }
          />
        ) : null}
        {party.contact?.phone ? (
          <PartyDetailsItem
            label={t('party.phone')}
            value={
              <Anchor external href={`tel:${party.contact.phone}`}>
                {party.contact.phone}
              </Anchor>
            }
          />
        ) : null}
        {party.contact?.email ? (
          <PartyDetailsItem
            label={t('party.email')}
            value={
              <Anchor external href={`mailto:${party.contact.email}`}>
                {party.contact.email}
              </Anchor>
            }
          />
        ) : null}
      </dl>
      {currentParty.partyWebsite || party.webSite ? (
        <Anchor
          href={formatUrl(currentParty.partyWebsite || party.webSite || '')}
          target="_blank"
          external
          sx={{ color: Colors.Secondary, ...itemStyle }}
        >
          <Text as="span" variant={TextVariant.Paragraph}>
            {t('party.website')}
          </Text>
          <span css={{ minWidth: 16 }}>
            <OpenNewWindowIcon aria-label="Åbner i nyt vindue" />
          </span>
        </Anchor>
      ) : null}
      {children}
    </div>
  );
}

export default PartyDetails;
