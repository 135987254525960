import React from 'react';
import { Heading } from '@charlietango/ui';
import { rem } from 'polished';

import { SchemaFrontendUserDataViewModel } from '../../api/api-schema';
import {
  CollectionState,
  ElectionType,
  electionTypeMap,
} from '../../api/api-types';
import { useParty } from '../../api/hooks/useParty';
import { useDictionary } from '../../hooks/useDictionary';
import { useElectionType, usePartyId } from '../../hooks/useSearchParams';
import { TextVariant } from '../../styles/typography';
import Logo from '../Logo/Logo';
import Placeholder from '../Placeholder/Placeholder';
import { DeclarationStatusContent } from './DeclarationStatus';

export type MyDeclarationProps = {
  user?: SchemaFrontendUserDataViewModel;
  className?: string;
};

/**
 * Show the users current declaration status
 * */
function MyDeclaration({ user, className }: MyDeclarationProps) {
  const { t } = useDictionary();
  const { partyId } = usePartyId();
  const { electionType, isDefined } = useElectionType();

  // Determine the voting rights for the user - Can they actually have a declaration?
  const votingRights = user?.votingRights || {};

  // Find out if the user has declared to this party (or another party in the same electionType)
  let currentDeclaration = user?.userDeclarations?.find(
    (item) => item.electionType === electionType,
  );

  if (!currentDeclaration && !isDefined && user?.userDeclarations?.length) {
    // We don't need a specific declaration, so choose the active one
    currentDeclaration = user.userDeclarations[0];
  }
  // Fetch the party for the current page, and the declared party - You can only be declared to one party, so we need to know if they are the same.
  const { data: party, status } = useParty(partyId);
  const { data: declaredParty, status: declaredStatus } = useParty(
    currentDeclaration?.partyId,
  );

  const currentParty = party || declaredParty;

  if (!user || declaredStatus === 'loading' || status === 'loading') {
    return <Placeholder size="small" />;
  }

  // Find the correct electionType for this view. Prefer the electionType in URL, otherwise go with the election
  const selectedElectionType = isDefined
    ? electionType
    : currentDeclaration?.electionType ?? ElectionType.Folketingsvalg;

  // Determine the current party state, and fallback to no collection.
  const { state } = (currentParty &&
    currentParty[electionTypeMap[selectedElectionType]]) ?? {
    state: CollectionState.noCollection,
  };

  return (
    <section className={className}>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: rem(30),
          mb: 7,
          pl: 3,
        }}
      >
        <Logo css={{ height: rem(35), width: rem(35) }} animateIn />
        <Heading as="h3" variant={TextVariant.H3} sx={{ ml: 3 }}>
          {t('myDeclaration.title')}
        </Heading>
      </div>
      <DeclarationStatusContent
        votingRights={votingRights}
        electionType={selectedElectionType}
        party={currentParty}
        partyState={state}
        declaredParty={declaredParty}
        declaration={currentDeclaration}
      />
    </section>
  );
}

export default MyDeclaration;
