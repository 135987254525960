import React from 'react';
import { Text } from '@charlietango/ui';
import { rem } from 'polished';

import {
  SchemaPartyDetailsResponse,
  SchemaUserDeclaration,
  SchemaVotingRights,
} from '../../api/api-schema';
import {
  CollectionState,
  ElectionType,
  electionTypeMap,
} from '../../api/api-types';
import { useRouter } from '../../application/Router';
import { useDictionary } from '../../hooks/useDictionary';
import BekraeftetIcon from '../../icons/BekraeftetIcon';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { dotFormatDate } from '../../utils/format-utils';
import Anchor from '../Anchor/Anchor';
import RichText from '../RichText/RichText';
import { ConfirmDeclaration, WithdrawDeclaration } from './DeclarationButtons';

type DeclarationStatusProps = {
  party?: SchemaPartyDetailsResponse;
  declaredParty?: SchemaPartyDetailsResponse;
  partyState: CollectionState;
  declaration?: SchemaUserDeclaration;
  votingRights: SchemaVotingRights;
  electionType: ElectionType;
};

type InterpolationValues = {
  electionType?: React.ReactNode;
  partyName?: React.ReactNode;
  daysLeft?: React.ReactNode;
  activeParty?: React.ReactNode;
  confirmExpireDate?: React.ReactNode;
};

export enum UserStatus {
  notYetInitiated = 'no-yet-initiated',
  initiated = 'initiated',
  readyToConfirm = 'confirm',
  confirmed = 'confirmed',
  activitiesWithAnotherParty = 'activities-with-another-party',
}

export function getDeclarationStatus(
  party?: SchemaPartyDetailsResponse,
  declaration?: SchemaUserDeclaration,
) {
  if (
    declaration &&
    (declaration.dateOfInitiation || declaration.dateOfConfirmation)
  ) {
    if (party && party.id !== declaration.partyId) {
      return UserStatus.activitiesWithAnotherParty;
    }
    if (declaration.dateOfConfirmation) {
      return UserStatus.confirmed;
    }

    if (declaration.daysLeft && declaration.daysLeft > 0) {
      return UserStatus.initiated;
    }
    return UserStatus.readyToConfirm;
  }
  return UserStatus.notYetInitiated;
}

/**
 * The wrapper around Declaration status items, that handles rendering the background.
 * */
const DeclarationBox = ({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled?: boolean;
}) => (
  <div
    sx={{
      display: 'flex',
      listStyle: 'none',
      backgroundColor: disabled ? Colors.DisabledGreen : Colors.InfoGreen,
      boxShadow: disabled
        ? '0 1px 0 0 #ebf6f6, inset 0 1px 0 0 #cce8e7'
        : '0 1px 0 0 #cce8e7, inset 0 1px 0 0 #cce8e7',
      color: disabled ? Colors.DisabledGreenText : undefined,
      py: 4,
      pl: 5,
      pr: 7,
    }}
  >
    {children}
  </div>
);

export const DeclarationItem = (props: {
  checkbox?: boolean;
  fillWidth?: boolean;
  content: React.ReactNode;
  date?: string | null;
  disabled?: boolean;
}) => {
  return (
    <DeclarationBox disabled={props.disabled}>
      {!props.fillWidth && (
        <div sx={{ width: rem(40), textAlign: 'center' }}>
          {props.checkbox && <BekraeftetIcon />}
        </div>
      )}
      <div
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          variant: 'text.' + TextVariant.Paragraph,
        }}
      >
        <Text as={props.date ? 'span' : 'p'}>{props.content}</Text>
        {props.date && (
          <time dateTime={props.date}>{dotFormatDate(props.date)}</time>
        )}
      </div>
    </DeclarationBox>
  );
};

export const DeclarationStatusContent = ({
  party,
  declaredParty,
  partyState = CollectionState.noCollection,
  declaration,
  electionType = ElectionType.Folketingsvalg,
  votingRights,
}: DeclarationStatusProps) => {
  const { pathname } = useRouter();
  const { t } = useDictionary();

  let userStatus: UserStatus = getDeclarationStatus(party, declaration);

  const {
    daysLeft,
    dateOfInitiation,
    dateOfConfirmation,
    dateOfConfirmationExpiry,
  } = declaration || {};

  const electionTypeValue = electionTypeMap[electionType];

  if (
    (electionType === ElectionType.Folketingsvalg && !votingRights.dk) ||
    (electionType === ElectionType.EuropaParlamentsvalg && !votingRights.eu)
  ) {
    return (
      <DeclarationBox>
        <RichText>
          {t('myDeclaration.status_13_13', {
            electionType: t(`${electionTypeValue}Election`),
            asString: true,
          })}
        </RichText>
      </DeclarationBox>
    );
  }

  if (!party || !party.id) {
    return (
      <DeclarationItem
        fillWidth
        content={t('myDeclaration.status_13_09', {
          electionType: t(`${electionTypeValue}Election`),
        })}
      />
    );
  }

  const interpolation: InterpolationValues = {
    partyName: party.name,
    electionType: t(`${electionTypeValue}Election`),
    daysLeft:
      daysLeft !== undefined
        ? t(daysLeft !== 1 ? 'myDeclaration.days' : 'myDeclaration.days_1', {
            days: daysLeft.toString(),
          })
        : undefined,
    activeParty: declaredParty ? (
      <Anchor
        href={`${pathname}?election=${electionTypeMap[electionType]}&party=${declaredParty?.id}`}
      >
        {declaredParty?.name}
      </Anchor>
    ) : undefined,
    confirmExpireDate: dateOfConfirmationExpiry ? (
      <time dateTime={dateOfConfirmationExpiry}>
        {dotFormatDate(dateOfConfirmationExpiry)}
      </time>
    ) : null,
  };

  switch (partyState) {
    case CollectionState.running:
      switch (userStatus) {
        case UserStatus.notYetInitiated:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_09', interpolation)}
            />
          );
        case UserStatus.initiated:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_01')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_03', interpolation)}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_01_01')}
                disabled={true}
              />
              <ConfirmDeclaration
                electionType={electionType}
                partyId={party.id}
                disabled={true}
                action="confirm"
              />
              <WithdrawDeclaration
                electionType={electionType}
                partyId={party.id}
                action="withdraw"
              />
            </>
          );
        case UserStatus.readyToConfirm:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_01')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_04', interpolation)}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_01_01')}
                disabled={true}
              />
              <ConfirmDeclaration
                electionType={electionType}
                partyId={party.id}
                action="confirm"
              />
              <WithdrawDeclaration
                electionType={electionType}
                partyId={party.id}
                action="withdraw"
              />
            </>
          );
        case UserStatus.confirmed:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02_01')}
                date={dateOfConfirmation}
              />
              <WithdrawDeclaration
                electionType={electionType}
                partyId={party.id}
                action="withdraw"
              />
            </>
          );
        case UserStatus.activitiesWithAnotherParty:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_08', interpolation)}
            />
          );
      }
      break;
    case CollectionState.locked:
      switch (userStatus) {
        case UserStatus.notYetInitiated:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_07_02', interpolation)}
            />
          );
        case UserStatus.initiated:
        case UserStatus.readyToConfirm:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_01')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_06_02', interpolation)}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_01_01')}
                disabled={true}
              />
              <WithdrawDeclaration
                electionType={electionType}
                partyId={party.id}
                action="withdraw"
              />
            </>
          );
        case UserStatus.confirmed:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02_01')}
                date={dateOfConfirmation}
              />
            </>
          );
        case UserStatus.activitiesWithAnotherParty:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_08_02', interpolation)}
            />
          );
        default:
          break;
      }
      break;
    case CollectionState.approved:
      switch (userStatus) {
        case UserStatus.initiated:
        case UserStatus.readyToConfirm:
        case UserStatus.notYetInitiated:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_07_03', interpolation)}
            />
          );
        case UserStatus.confirmed:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02_01')}
                date={dateOfConfirmation}
              />
            </>
          );
        case UserStatus.activitiesWithAnotherParty:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_08_03', interpolation)}
            />
          );
      }
      break;
    case CollectionState.noCollection:
      switch (userStatus) {
        case UserStatus.initiated:
        case UserStatus.confirmed:
        case UserStatus.readyToConfirm:
        case UserStatus.notYetInitiated:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_11', interpolation)}
            />
          );
        case UserStatus.activitiesWithAnotherParty:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_08_04', interpolation)}
            />
          );
      }
      break;
    case CollectionState.deactivated:
      switch (userStatus) {
        case UserStatus.notYetInitiated:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_07_01', interpolation)}
            />
          );
        case UserStatus.initiated:
        case UserStatus.readyToConfirm:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_01')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_06_01', interpolation)}
              />
              <DeclarationItem
                content={t('myDeclaration.status_13_01_01')}
                disabled={true}
              />
              <WithdrawDeclaration
                electionType={electionType}
                partyId={party.id}
                action="withdraw"
              />
            </>
          );
        case UserStatus.confirmed:
          return (
            <>
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02')}
                date={dateOfInitiation}
              />
              <DeclarationItem
                checkbox={true}
                content={t('myDeclaration.status_13_02_01')}
                date={dateOfConfirmation}
              />
              <WithdrawDeclaration
                electionType={electionType}
                partyId={party.id}
                action="withdraw"
              />
            </>
          );
        case UserStatus.activitiesWithAnotherParty:
          return (
            <DeclarationItem
              fillWidth
              content={t('myDeclaration.status_13_08_01', interpolation)}
            />
          );
      }
  }
  return null;
};
