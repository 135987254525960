import React, { useEffect, useState } from 'react';
import { Text } from '@charlietango/ui';
import { rem } from 'polished';

import { SchemaFrontendUserDataViewModel } from '../../api/api-schema';
import {
  CollectionState,
  ElectionType,
  electionTypeMap,
} from '../../api/api-types';
import { useAuth } from '../../api/AuthProvider';
import useAddDeclaration from '../../api/hooks/useAddDeclaration';
import { useRouter } from '../../application/Router';
import { useDictionary } from '../../hooks/useDictionary';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { DeclarationActionTypes } from '../../view-models/DeclarationActionTypes';
import Anchor from '../Anchor/Anchor';
import Arrow from '../Arrow/Arrow';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import RichText from '../RichText/RichText';
import InitiateDeclarationViewModel from './types/InitiateDeclarationViewModel';
import { getErrorWithState } from '../StatusMessages/StatusMessageOverlay';

type Props = {
  model?: InitiateDeclarationViewModel;
  className?: string;
  electionType?: ElectionType;
  collectionState: CollectionState;
  partyId?: string;
  partyName?: string | null;
  user?: SchemaFrontendUserDataViewModel;
};

enum ModalState {
  Hidden,
  Show,
  Error,
}

function InitiateDeclaration({
  model,
  className,
  electionType,
  partyId,
  partyName,
  collectionState,
  user,
}: Props) {
  const { searchParams, replace, updateSearchParams } = useRouter();
  const { loginUrl } = useAuth();
  const { t } = useDictionary();
  const [state, setState] = useState(undefined);

  const [submitDeclaration, { status }] = useAddDeclaration({
    onSettled: (data, error) => {
      if (!data || error) {
        setModalState(ModalState.Error);
        if (error?.details?.hasOwnProperty('stateCode')) {
          setState(error.details['stateCode']);
        }
      } else {
        setModalState(ModalState.Hidden);
      }
    },
  });

  const [modalState, setModalState] = useState<ModalState>(ModalState.Hidden);
  const action = searchParams?.get('action');

  useEffect(() => {
    if (action === DeclarationActionTypes.initiate) {
      // Remove the failure code from the URL
      updateSearchParams([{ key: 'action', value: undefined }]);

      setModalState(ModalState.Show);
    }
  }, [action, updateSearchParams, user, replace]);

  const onDismiss = () => setModalState(ModalState.Hidden);

  const alreadyDeclared = user?.userDeclarations?.some(
    (declaration) => declaration.electionType === electionType,
  );

  if (
    // Ensure the party is actually collecting at the moment
    collectionState !== CollectionState.running ||
    // Ensure the user isn't already declared - No sense in declaring twice
    alreadyDeclared ||
    // Ensure the user has voting rights
    (electionType !== undefined &&
      user &&
      !user?.votingRights?.[electionTypeMap[electionType]]) ||
    // Ensure we have a login url
    !loginUrl
  ) {
    return null;
  }

  const defaultMessage = 'errors.generic_text';

  return (
    <div
      className={className}
      css={{
        position: 'relative',
      }}
    >
      {user ? (
        <Button
          variant={ButtonVariant.Primary}
          icon={<Arrow />}
          onClick={() => {
            setModalState(ModalState.Show);
          }}
          label={model?.initiateLabel}
          disabled={status === 'loading'}
        />
      ) : (
        <Button
          variant={ButtonVariant.Primary}
          icon={<Arrow />}
          href={
            loginUrl +
            encodeURIComponent('&action=' + DeclarationActionTypes.initiate)
          }
          label={model?.initiateLabel}
        />
      )}
      <div
        sx={{
          mt: 6,
          mb: [8, 0],
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!user && (
          <Text variant={TextVariant.Paragraph}>
            {model?.alreadyDeclaredText}
            {model?.loginLabel && (
              <Anchor
                prefetch={false}
                external
                sx={{
                  ml: '5px',
                  fontSize: rem(14),
                  lineHeight: 1.71,
                  transition: 'color .3s ease',
                  color: Colors.Secondary,
                  '&:hover': {
                    color: Colors.Black,
                  },
                }}
                label={model?.loginLabel}
                href={loginUrl}
              />
            )}
          </Text>
        )}
      </div>
      {partyId !== undefined && electionType !== undefined ? (
        <Modal
          title={t(
            modalState === ModalState.Error
              ? 'errors.generic_title'
              : 'modal.status_14_01_title',
            {
              asString: true,
              party: partyName,
              electionType: t(`${electionTypeMap[electionType]}Election`),
            },
          )}
          isOpen={modalState !== ModalState.Hidden && user !== undefined}
          onDismiss={onDismiss}
          actions={
            modalState === ModalState.Show
              ? [
                  {
                    onClick: () => {
                      submitDeclaration({
                        partyId,
                        electionType,
                      });
                    },
                    label: t('modal.accept'),
                    variation: ButtonVariant.Primary,
                    submitting: status === 'loading',
                  },
                  {
                    label: t('modal.cancel'),
                    onClick: onDismiss,
                    variation: ButtonVariant.Flat,
                  },
                ]
              : undefined
          }
        >
          <RichText>
            {t(
              modalState === ModalState.Error
                ? getErrorWithState(state, 'initiate', defaultMessage)
                : 'modal.status_14_01_text',
            )}
          </RichText>
        </Modal>
      ) : null}
    </div>
  );
}

export default InitiateDeclaration;
