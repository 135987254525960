/*
 GENERATED FILE - DO NOT MODIFY
 Any changes will be overwritten when `yarn svgs` is executed.
 
 Generated from: assets/bekraeftet.svg
 If you need to make changes the attributes, modify the original .svg file.
 
 If you need to modify the file further, consider copying this generated file to a new location,
 or into the component that uses it. If you do this, it might make sense to remove the original .svg file, 
 or it could be regenerated the next time SVGs are generated.
*/
import * as React from 'react';
const BekraeftetIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, svgRef: any) => (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      focusable={false}
      ref={svgRef}
      {...props}
    >
      <g fill="#333" fillRule="evenodd">
        <path d="M1.929 10.9l5.657 5.656-1.415 1.415-5.656-5.657z" />
        <path d="M16.07 5.243l1.415 1.414L6.171 17.97l-1.414-1.415z" />
      </g>
    </svg>
  ),
);
export default BekraeftetIcon;
