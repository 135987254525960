import React from 'react';
import { Text } from '@charlietango/ui';

import { TextVariant } from '../../styles/typography';

type PartyDetailsType = {
  label: React.ReactNode;
  ariaValue?: string;
  value: React.ReactNode;
};
export const itemStyle = {
  boxShadow: ' inset 0 1px 0 0 #dce1e1',
  display: 'flex',
  justifyContent: 'space-between',
  py: 4,
};

function PartyDetailsItem({ label, value, ariaValue }: PartyDetailsType) {
  return (
    <div sx={itemStyle}>
      <Text
        as="dt"
        variant={TextVariant.Paragraph}
        sx={{ flex: '0 0 auto', mr: 4 }}
      >
        {label}
      </Text>
      <Text
        as="dd"
        variant={TextVariant.Paragraph}
        sx={{ flex: '1 1 auto', textAlign: 'right' }}
        aria-label={ariaValue}
      >
        {value}
      </Text>
    </div>
  );
}
export default PartyDetailsItem;
