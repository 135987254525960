import React from 'react';
import { rem } from 'polished';

import { ElectionType, electionTypeMap } from '../../api/api-types';
import { useRouter } from '../../application/Router';
import { useDictionary } from '../../hooks/useDictionary';
import { useElectionType } from '../../hooks/useSearchParams';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import Button from '../Button/Button';

const btnStyle = (active: boolean) => ({
  py: 1,
  px: 0,
  fontSize: [rem(11), rem(14)],
  flex: '0 1 auto',
  width: 'auto',
  color: active ? Colors.Black : Colors.Secondary,
  borderBottom: '2px solid',
  borderBottomColor: active ? Colors.Primary : 'transparent',
});

type Props = {
  className?: string;
};

function ElectionTypeNavigation({ className }: Props) {
  const { searchParams, pathname } = useRouter();
  const { electionType } = useElectionType();
  const { t } = useDictionary();

  const constructUrl = (key) => {
    const query = new URLSearchParams(
      searchParams ? searchParams.toString() : undefined,
    );

    query.set('election', key);
    return `${pathname}?${query.toString()}`;
  };

  return (
    <nav
      sx={{
        backgroundColor: '#f4f3f3',
        border: '1px solid',
        borderColor: Colors.VeryLightPink,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        py: [5, 9],
        px: [5, 8],
        mx: [rem(-20), 0],
        textTransform: 'uppercase',
      }}
      className={className}
    >
      <Button
        prefetch
        href={constructUrl(electionTypeMap[ElectionType.Folketingsvalg])}
        variant={ButtonVariant.Flat}
        replaceHistory
        sx={{
          ...btnStyle(electionType === ElectionType.Folketingsvalg),
          mr: [4, 7],
          wordBreak: 'break-word',
        }}
      >
        {t('dkElection')}
      </Button>
      <Button
        prefetch
        href={constructUrl(electionTypeMap[ElectionType.EuropaParlamentsvalg])}
        variant={ButtonVariant.Flat}
        replaceHistory
        sx={btnStyle(electionType === ElectionType.EuropaParlamentsvalg)}
      >
        {t('euElection')}
      </Button>
    </nav>
  );
}

export default ElectionTypeNavigation;
